<template>
  <div class="container-page">
    <p class="title-inner">Реестры</p>
    <div class="mb-16">
      <ButtonStock title="Экспорт" @click="exportRegistry" />
    </div>
    <DefaultFilter :filterValue="currentFilter" @loadFilter="onLoadFilter" />
    <tabs v-if="regTitles" v-on:select="tabSelected">
      <tab v-for="(title, index) in regTitles" :name="title" :selected="index === 0" :key="index">
        <div>
          <div class="table-container mb-16 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th>Номер заявления</th>
                  <th>Пользователь</th>
                  <th>Статус</th>
                  <th>Период даты подачи</th>
                  <th>Текст</th>
                  <th></th>
                </tr>
                <tr>
                  <th class="filter">
                    <div class="small">
                      <FilterInput
                        v-model="registryBag[index].filter.id"
                        placeholder="Введите..."
                        v-on:valueChangedDebounced="changeFilter(index)"
                      />
                    </div>
                  </th>
                  <th class="filter">
                    <FilterInput
                      type="select2"
                      :select2-options="employees"
                      :select2-settings="select2Settings"
                      v-model="registryBag[index].filter.userIds"
                      multiple
                      placeholder="(Любой)"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </th>
                  <th class="filter">
                    <FilterInput
                      type="select2"
                      :select2-options="statuses"
                      :select2-settings="select2Settings"
                      v-model="registryBag[index].filter.statusIds"
                      multiple
                      placeholder="(Любой)"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </th>
                  <th class="filter">
                    <div class="range">
                      <FilterInput
                        type="date"
                        v-model="registryBag[index].filter.dateFrom"
                        class="mr-2"
                        v-on:valueChangedDebounced="changeFilter(index)"
                      />
                      <FilterInput
                        type="date"
                        v-model="registryBag[index].filter.dateTo"
                        v-on:valueChangedDebounced="changeFilter(index)"
                      />
                    </div>
                  </th>
                  <th class="filter">
                    <FilterInput
                      v-model="registryBag[index].filter.body"
                      placeholder="Введите искомый текст..."
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="registryBag[index].page?.items?.length && !registryBag[index].isLoading">
                <tr v-for="item in registryBag[index].page.items" :key="item.id" @dblclick="viewCard(item.id)">
                  <td>{{ item.id }}</td>
                  <td>{{ item.createdBy?.fullName }}</td>
                  <td>{{ item.moderationStatus.title }}</td>
                  <td>{{ $momentFormat(item.created, 'DD.MM.YYYY HH:mm') }}</td>
                  <td>
                    <p v-snip="3">{{ item.body }}</p>
                  </td>
                  <td>
                    <IconComponent @click="viewCard(item.id)" name="mode" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="registryBag[index].isLoading">
                <tr>
                  <td class="text-center" colspan="4">Загрузка...</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center" colspan="4">Нет данных.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="registryBag[index].page.pagesTotal > 1">
            <Pagination
              :page-count="registryBag[index].page.pagesTotal"
              :current-page="registryBag[index].page.pageNumber"
              @change="onPageChange($event, false, index)"
              @more="onPageChange($event, true, index)"
            />
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import FilterInput from '../../../common/components/FilterInput';
  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import UserApi from '../../admin/api/users';
  import Api from '../api/registry';
  export default {
    name: 'Registry',
    components: { FilterInput, ButtonStock, IconComponent, tabs, tab, DefaultFilter },
    mixins: [filtermanager],
    data() {
      return {
        registryBag: [],
        regTitles: ['Реестр сообщений о неверной информации', 'Реестр предложений по оптимизации процедуры'],
        currentRegType: 0,
        employees: [],
        statuses: [],
        select2Settings: Constants.select2SettingsMultiple,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filters = response;
        this.loadDictionaries();
        this.registryBag = [];
        for (let type = 0; type < 2; type++) {
          this.registryBag.push({
            page: {},
            request: {
              pageNumber: 1,
              pageSize: Constants.pageSize,
              registryType: type,
            },
            filter: (filters && filters[type]) ?? {
              userIds: [],
              statusIds: [],
              dateFrom: null,
              dateTo: null,
              body: null,
              isActive: null,
            },
            isLoading: false,
          });
          this.loadPage(type);
        }
      });
    },
    methods: {
      loadPage(type, append) {
        this.registryBag[type].isLoading = true;
        const request = Object.assign(this.registryBag[type].filter, this.registryBag[type].request);
        Api.search(request)
          .then((response) => {
            if (!append) {
              this.registryBag[type].page = {};
              this.registryBag[type].page = response.data;
            } else if (response.data) {
              this.registryBag[type].page.pageNumber = response.data.pageNumber;
              this.registryBag[type].page.items = this.registryBag[type].page.items.concat(response.data.items);
            }
            this.registryBag[type].isLoading = false;
          })
          .catch((error) => {
            this.registryBag[type].isLoading = false;
            console.error(error?.response?.data);
          });
      },
      loadDictionaries() {
        UserApi.search(this.defaultRequest)
          .then((response) => {
            this.employees = response.data.items.map((x) => {
              return { text: x.fullName, id: x.userId };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        Api.getModerationStatuses()
          .then((response) => {
            this.statuses = response.data.map((x) => ({ id: x.id, text: x.title }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      viewCard(id) {
        this.$router.push({ name: 'NavigatorRegistryCard', params: { id: id, action: 'view' } });
      },
      exportRegistry() {
        let request = Object.assign({}, this.registryBag[this.currentRegType].request);
        request = Object.assign(request, this.registryBag[this.currentRegType].filter);
        Api.exportRegistry(request);
      },
      onPageChange(pageNumber, addToExist, type) {
        this.registryBag[type].request.pageNumber = pageNumber;
        this.loadPage(type, addToExist);
      },
      tabSelected(index) {
        this.currentRegType = index;
      },
      changeFilter(type) {
        this.registryBag[type].request.pageNumber = 1;
        this.saveFilter(this.currentFilter);
        this.loadPage(type, false);
      },
      onLoadFilter(item) {
        for (let type = 0; type < 2; type++) {
          this.registryBag[type].filter = (item && item[type]) ?? {
            userIds: [],
            statusIds: [],
            dateFrom: null,
            dateTo: null,
            body: null,
            isActive: null,
          };
          this.changeFilter();
        }
      },
    },
    computed: {
      currentFilter() {
        let filters = [];
        for (let i = 0; i < this.registryBag.length; i++) filters.push(this.registryBag[i].filter);
        return filters;
      },
    },
  };
</script>

<style lang="scss">
  .filter {
    .range {
      display: flex;
      .input-group-wrapper {
        input {
          padding: 4px 8px;
          min-width: 100px;
          max-width: 100px;
        }
      }
    }
    .small input {
      max-width: 90px;
    }
  }
</style>
